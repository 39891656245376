define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-ca", {
    "Danish": "Danish",
    "Dutch": "Dutch",
    "English": "English",
    "French": "French",
    "German": "German",
    "Norsk": "Norsk",
    "account": {
      "account": "Account Settings",
      "accountConfirmed": "Account Confirmed",
      "accountCreated": "Account Created",
      "accountSettings": "Account Settings",
      "adp": {
        "connectorCode": "ADP Connector Code",
        "connectorListingURL": "https://ca.apps.adp.com/en-CA/apps/442886",
        "integration": "ADP Integration",
        "integrationDescription": "Integrate your ADP account with Thrive to streamline your user enrollment processes.",
        "integrationLearnMore": "Click here to learn more."
      },
      "appVersion": "App Version",
      "changeEmailAddress": "Change Email Address",
      "changeEmailMsg": "You will need to sign in after changing your email address.",
      "changePicture": "Change Picture",
      "confirm": "Confirm",
      "confirmNewPassword": "Confirm New Password",
      "confirmTotp": "3. Confirm TOTP",
      "currentPassword": "Current Password",
      "disable": "Disable",
      "disableMobileAppAuth": "Disable Mobile App Authentication",
      "domains": "Domains",
      "enterTheSixDigit": "Enter the six digit code displayed by your authenticator app:",
      "firstName": "First Name",
      "googleAuthenticator": "Google Authenticator",
      "gravatarImagesMsg": "We use Gravatar images for profile photos.",
      "installMobileAuth": "1. Install Mobile Authenticator App",
      "lastName": "Last Name",
      "logInAgain": "Email updated, you will need to log in again",
      "logout": "Logout",
      "mfaDisabled": "Multi Factor Authentication Disabled",
      "mfaEnabled": "Multi Factor Authentication Enabled",
      "microsoftAuthenticator": "Microsoft Authenticator",
      "mobileAppAuthentication": "Mobile App Authentication",
      "mustConfirmBeforeDisableMFA": "To disable Mobile App Authentication we must confirm your account access",
      "newPassword": "New Password",
      "orInputCode": "Or input this code into your authenticator app manually:",
      "password": "Password",
      "passwordMsg": "Passwords must be 8-70 characters long and include: 1 uppercase, 1 lowercase, 1 digit and 1 special character.",
      "passwordUpdated": "Password Updated",
      "phone": "Phone",
      "portalSettings": "Portal Settings",
      "profile": "Profile",
      "profileNotUpdated": "Unable to update Profile",
      "profilePhoto": "Profile Photo",
      "profileUpdated": "Profile Updated",
      "scanQrCode": "2. Scan QR Code with Authenticator App",
      "secureYourAccountWithTotp": "Secure your account with TOTP (Time-based One-time Password) authentication.",
      "securedWithTotp": "Your account is secured with TOTP (Time-based One-time Password) authentication.",
      "setup": "Setup",
      "setupMobileAppAuth": "Setup Mobile App Authentication",
      "systemSettings": "System Settings",
      "twoFactorAuthentication": "Two Factor Authentication",
      "updateEmail": "Update Email",
      "updatePassword": "Update Password",
      "updateProfile": "Update Profile",
      "updateYourEmail": "Update Your Email",
      "willLogYouOut": "This will log you out!",
      "youCanUseAnyTotp": "You can use any TOTP (Time-based One-time Password) compatible app."
    },
    "admins": {
      "addNewAdmin": "Add new admin?",
      "deleteSubject": "Admin Account",
      "invite": "Invite Admin",
      "inviteConfirmation": {
        "asAn": "as a(n)",
        "confirm": "Are you sure you want to invite",
        "toThe": "to the"
      },
      "modal": {
        "buttons": {
          "invite": "Invite Admin",
          "save": "Save Admin"
        },
        "emailWillBeSent": "An email invite will be sent to prompt this Admin to create a password.",
        "labels": {
          "authRole": "Auth Role - Optional",
          "client": "Client - Optional",
          "email": "Email",
          "firstName": "First Name",
          "lastName": "Last Name",
          "locale": "Preferred Language",
          "office": "Office",
          "permissionLevel": "Permission Level",
          "phoneNumber": "Phone Number - Optional",
          "position": "Position - Optional",
          "profile": "Profile - Optional",
          "profileOptions": {
            "employer": "Employer"
          },
          "required": "Required"
        },
        "title": "Admin"
      },
      "noAdminsFound": "No admins found.",
      "search": "Search",
      "searchInputLabel": "Search Admin Members",
      "show": {
        "adminInfo": "Admin Info",
        "breadcrumbs": {
          "adminLookup": "Admin Lookup",
          "manageAdmin": "Manage Admin"
        },
        "email": "Email",
        "featureSwitchId": "Feature Switch ID",
        "name": "Name",
        "table": {
          "headers": {
            "activatedOn": "Activated On",
            "createdOn": "Created On",
            "customer": "Customer",
            "email": "Email",
            "firstName": "First Name",
            "lastName": "Last Name",
            "phoneNumber": "Phone Number",
            "portalStatus": "Portal Status",
            "serviceStatus": "Service Status"
          },
          "noResults": "No assigned users.",
          "unknown": "Unknown"
        },
        "title": "Admin Details"
      },
      "table": {
        "headers": {
          "authRole": "Auth Role",
          "client": "Client",
          "confirmed": "Confirmed",
          "email": "Email",
          "name": "Name",
          "permission": "Permission",
          "position": "Position"
        },
        "resend": {
          "button": {
            "default": "Resend Invite",
            "sending": "Sending..."
          },
          "confirmation": "Are you sure you want to resend an invite to this admin?"
        }
      },
      "title": "Admin Members",
      "toast": {
        "failure": "Invite failed to send.",
        "success": "Admin invite email re-sent."
      }
    },
    "common": {
      "addNew": "Add New",
      "and": "and",
      "cancel": "Cancel",
      "candidateSearch": "Candidate Search",
      "caseClosureDate": "Case Closure Date",
      "changesSaved": "Changes saved",
      "commonServiceDelivery": "Common Service Delivery (CSD)",
      "create": "Create",
      "customers": "Customers",
      "dashboard": "Dashboard",
      "deactivate": "Deactivate",
      "deactivated": "Deactivated",
      "delete": "Delete",
      "deleted": "Deleted",
      "doNotHavePermission": "You do not have permission to view this page.",
      "dontSend": "Don't Send",
      "download": "Download",
      "edit": "Edit",
      "externalId": "External ID",
      "false": "False",
      "home": "Home",
      "in": "in",
      "incomeSupport": "Income Support (IS)",
      "loading": "Loading",
      "madeWith": "Made with",
      "manage": "Manage",
      "na": "N/A",
      "new": "New",
      "no": "No",
      "note": "Note",
      "notes": "Notes",
      "now": "Now",
      "optional": "Optional",
      "passwordsMustMatch": "Passwords must match",
      "pleaseCompleteForm": "Please complete the form",
      "privacyPolicy": "privacy policy",
      "referredBy": "Referred By",
      "region": "Region",
      "register": "Register",
      "required": "Required",
      "revoke": "Revoke",
      "revoked": "Revoked",
      "save": "Save",
      "schedule": "Schedule",
      "search": "Search",
      "somethingWentWrong": "Something went wrong",
      "source": "Source",
      "successfullyCreated": "Successfully Created!",
      "successfullyUpdated": "Successfully Updated!",
      "termsOfService": "terms of service",
      "title": "Title",
      "tokens": "Tokens",
      "true": "True",
      "user": "User",
      "users": "Users",
      "view": "View",
      "yes": "Yes"
    },
    "customers": {
      "importUsers": "Import Users",
      "title": "Customer Management"
    },
    "dashboard": {
      "noData": {
        "addUsers": "Click here to add Users",
        "description": "User data is available once 10+ users have activated their accounts and have been on the platform for at least two weeks. This helps us keep user career transition data anonymous.",
        "header": "You do not have any user data yet.",
        "supportText": "If you have any questions, email our customer support team at"
      },
      "reporting": {
        "activatedUsers": {
          "action": "Users Activated",
          "title": "Activated",
          "tooltip": "Total users activated within the last 12 months. Percentage shows month over month change in activations."
        },
        "activeUsage": {
          "title": "Active Users"
        },
        "common": {
          "error": "Unable to submit request!",
          "monthly": "Monthly",
          "months": "Months",
          "past": "Past",
          "success": "Request submitted!",
          "weekly": "Weekly"
        },
        "engagedUsers": {
          "action": "Users Engaged",
          "title": "Engaged",
          "tooltip": "Total Users using the platform within the last 30 days who activated their account within 6 months."
        },
        "invitedUsers": {
          "action": "Invites Sent",
          "title": "Invited",
          "tooltip": "Total invites sent within the last 12 months.",
          "tooltipTitle": "Users Invited"
        },
        "jobResourcesUsed": {
          "noJobsSaved": "No Jobs Saved with SmartTracker Yet.",
          "title": "Job Resource Used"
        },
        "netPromoterScore": {
          "tooltip": "Industry average is 20. NPS score represents the percentage of users who rated their likelihood to recommend Thrive as 9 or 10 minus the percentage of users who rated this at 6 or below on a scale from 0 to 10. Read more about how NPS is calculated",
          "tooltipHere": "here",
          "tooltipTitle": "Net Promoter Score"
        },
        "nps": "NPS",
        "officeMetrics": {
          "accounts": "Accounts",
          "accountsDescription": "Rolling total user registrations",
          "activated": "Activated",
          "activatedDescription": "Active User Accounts",
          "conversion": "Conversion",
          "conversionDescription": "Activated Accounts / Invites sent",
          "invited": "Invited",
          "invitedDescription": "Email invites sent",
          "title": "Job Seeker Registrations"
        },
        "onboardedUsers": {
          "action": "Users Onboarded",
          "title": "Onboarded",
          "tooltip": "Total users onboarded within the last 12 months. Onboarded users have activated their account and had at least 1 service delivered."
        },
        "pendingUserRegistrations": {
          "noPendingUsers": "No pending users found. Great work!",
          "title": "Pending User Registrations",
          "viewFullPage": "View Full Page"
        },
        "pieChart": {
          "title": "Transition Priority",
          "tooltip": "Users transition priority over the last 90 days.",
          "tooltipTitle": "Users Transition Priority"
        },
        "recentJobResources": {
          "title": "Recently Added Job Resources"
        },
        "requestCustomReport": {
          "description": "Please submit the form below to request a custom report from our support team. We are happy to help and can often answer to most questions within hours. Someone from our team will reach out to you within 1 business day.",
          "reportRequest": "Report Request",
          "reportRequestDescription": "Please be as detailed as possible about the data and format you are looking for.",
          "reportTitle": "Report Title",
          "submit": "Submit Custom Report Request",
          "subtitle": "Looking for something else?",
          "title": "Request Custom Report"
        },
        "requestOnsiteSupport": {
          "careerTransitionProgramLabel": "Career Transition Program",
          "dateAndTimeLabel": "Date and time visit requested",
          "description": "Please submit the form below to request onsite support, someone from our team will reach out to you within 1 business day. Please note that there are costs associated with onsite support.",
          "employeeNameLabel": "Employee Name",
          "hrNameLabel": "HR Contact Name",
          "hrOnsiteNameLabel": "HR Contact to ask for upon arrival",
          "hrPhoneNumberLabel": "HR Contact Phone Number",
          "jobTitleLabel": "Job Title",
          "notesDescription": "Please fill in as much information about the onsite support required as possible.",
          "notesLabel": "Notes",
          "reasonForDepartureLabel": "Reason for Departure",
          "siteAddressLabel": "Site Address",
          "submit": "Submit Onsite Support Request",
          "subtitle": "Looking for notification support for your affected employees?",
          "tenureLabel": "Tenure",
          "title": "Request Onsite Support",
          "virtualOrInPersonLabel": "Virtual or in-person support"
        },
        "spendingAndDeposits": {
          "deposits": "Deposits",
          "depositsLastYear": "Deposits in the last year",
          "spending": "Spending",
          "spendingLastYear": "Spending in the last year"
        },
        "stackedLineChart": {
          "title": "Spending by Category",
          "tooltip": "Spending by category for each month as a percentage of total spending. Excludes onboarding services."
        },
        "teamMembers": {
          "email": "Email",
          "setting": "Setting",
          "title": "Team Members"
        },
        "transitionedUsers": {
          "action": "Users Transitioned",
          "title": "Transitioned",
          "tooltip": "Total Users Transitioned in the last 12 months."
        },
        "userTestimonials": {
          "subtitle": "We collect feedback on an ongoing basis to keep improving the services we can offer.",
          "title": "Hear what Thrive users are saying!"
        }
      }
    },
    "dropdowns": {
      "delete": {
        "confirmation": "Are you sure you want to delete this"
      }
    },
    "fourOhFour": {
      "button": {
        "backToDashboard": "Back to Dashboard",
        "backToHome": "Back to Home"
      },
      "errors": {
        "contactSupport": "Please try again later or contact support for further assistance.",
        "invalidOptions": "Oops! It seems there are no options available for you at the moment.",
        "lostMessage": "Oops! It looks like you're a little lost..."
      },
      "title": "404 Page Not Found"
    },
    "incidentBanner": {
      "message": "We are currently experiencing performance issues, our team is aware of this and we appreciate your patience as we work to resolve these issues."
    },
    "jobBoard": {
      "index": {
        "addJobPostBtn": "Add Job Post",
        "companyName": "Company Name",
        "header": "Job Board",
        "jobTitle": "Job Title",
        "noJobPostingsBtn": "Click here to create a job post",
        "noJobPostingsHeader": "You have no job postings.",
        "noJobPostingsParagraph": "Job posting data will be available once they are created. If you have any questions email our customer support team at ",
        "noMatchingJobPosts": "No matching job posts found.",
        "notPublished": "Not Published",
        "pageTitle": "Job Board",
        "searchPlaceholder": "Search by Job Title or Company Name",
        "statusInactive": "Inactive",
        "statusPublished": "Published",
        "tableHeadings": {
          "companyName": "Company Name",
          "jobTitle": "Job Title",
          "publishedDate": "Published Date",
          "status": "Status"
        }
      },
      "jobPostEditor": {
        "breadcrumbs": {
          "edit": "Edit",
          "index": "Job Posts",
          "new": "New"
        },
        "description": "Post a job on Thrive where there are active and qualified job seekers. Your posting will be labeled as a <span class=\"Thrive-text font-weight-bold\">‘Thrive Opportunity’</span> for higher engagement.",
        "formFields": {
          "applicationDeadline": "Application deadline is ",
          "companyName": "Company Name",
          "employmentType": "Employment Type",
          "footer": "Thrive Opportunities are Job Posts from employers working with Thrive who are actively looking to fill roles.",
          "jobApplicationUrl": "Job Application URL",
          "jobDescription": "Job Description",
          "jobDescriptionSubtext": "Describe the responsibilities of this job, required work experience, skills, or education:",
          "jobTitle": "Job Title",
          "location": "Location",
          "locationPlaceholder": "Enter a location",
          "notPublished": "Not Yet Published",
          "organizationClient": "Organization Client",
          "publishLater": "Publish Later",
          "publishNow": "Publish Now",
          "publishTitle": "When do you want to publish?",
          "salary": "Salary",
          "salaryOptional": "Salary - Optional",
          "saveJob": "Save to Job Tracker",
          "selectPolicy": "Select Policy",
          "selectType": "Select Type",
          "viewDetails": "View Details",
          "workplacePolicy": "Workplace Policy",
          "workplacePolicySubtext": "Can this job be performed remotely? Is it a hybrid work environment?"
        },
        "jobTypes": {
          "apprenticeship": "Apprenticeship",
          "casual": "Casual",
          "comission": "Commission",
          "commission": "Commission",
          "contract": "Contract",
          "freelance": "Freelance",
          "fullTime": "Full Time",
          "full_time": "Full Time",
          "internship": "Internship",
          "partTime": "Part Time",
          "part_time": "Part Time",
          "permanent": "Permanent",
          "subcontract": "Freelance",
          "temporary": "Temporary",
          "volunteer": "Volunteer"
        },
        "jobWorkplacePolicies": {
          "hybrid": "Hybrid",
          "onsite": "On-Site",
          "remote": "Remote"
        },
        "opporunity_svg": "/assets/images/jobposts/ThriveOpportunity.svg",
        "placeholderPost": "<h3><u>Job Description</u></h3>The Job Description sets the stage for the rest of your job post.  Candidates browse through hundreds of jobs and the job summary may be the only section they read before deciding to read on.<h3><u>Responsibilities</u></h3><ul><li>Item 1</li><li>Item 2</li></ul><h3><u>Qualifications</u></h3><ul><li>Item 1</li><li>Item 2</li><li>Item 3</li></ul><h3><u>Company Description</u></h3>Add details about your company values, company history, and mission statement. A short elevator pitch of the company can help attract qualified candidates.`",
        "title": "Job Post Editor"
      },
      "jobPostSettings": {
        "createdOn": "Created On",
        "deleteJobPost": "Delete Job Post",
        "editJobPost": "Edit Job Post",
        "jobPostPreview": "Job Post Preview",
        "notPublished": "Not Published",
        "publish": "Publish",
        "publishedDate": "Published Date",
        "status": "Status",
        "statusInactive": "Inactive",
        "statusPublished": "Published",
        "title": "Post Settings",
        "unpublish": "Unpublish"
      }
    },
    "login": {
      "about": "About",
      "authCodeTitle": "Enter the six digit code from your authenticator app",
      "careerJsm": "CareerJSM",
      "contact": "Contact",
      "createAccount": "Create Account",
      "createHubAccount": "Create an Admin HUB Account",
      "downLoadChrome": " Download Google Chrome",
      "emailAssistance": "Didn’t receive a code after a few tries?",
      "emailAuthCodeTitle": "Enter the 6 digit code we emailed you",
      "enterANewPassword": "Enter a new Password",
      "errors": {
        "sso-token": {
          "invalid": "Invalid SSO Token, Please Contact Support",
          "missing": "No SSO Token Found, Please Contact Support"
        }
      },
      "forAssistance": "for assistance",
      "forgotPassword": "Forgot Password?",
      "hide": "HIDE",
      "hubTitle1": "The HUB is the admin dashboard.",
      "hubTitle2": "Here, you can manage your clients, users, and data.",
      "iAgreeTerms1": "I agree to the",
      "iAgreeTerms2": "terms of service",
      "iAgreeTerms3": "and",
      "iAgreeTerms4": "privacy policy.",
      "invalidEmailOrPW": "Invalid email or password",
      "login": "Log In",
      "lostAuthCodeApp": "Lost Authenticator App?",
      "notYou": "Not you? Click here.",
      "password": "Password",
      "phoneNumber": "Phone Number",
      "positionTitle": "Position/Title",
      "poweredBy": "PoweredBy",
      "privacyPolicy": "Privacy Policy",
      "recovery1": "Please contact ",
      "recovery2": "to begin account recovery.",
      "resend": "Resend",
      "resendVerify": "Didn't get the code? ",
      "resetInstructions1": "Enter your ",
      "resetInstructions2": "email address",
      "resetInstructions3": " to receive password reset instructions:",
      "resetPassword": "Reset Password",
      "show": "SHOW",
      "submit": "Submit",
      "support": "Support",
      "termsOfUse": "Terms of Use",
      "title": "Log in to the Admin Hub",
      "useChromeMsg": "You are not using Chrome. Please download or switch browsers for the best experience."
    },
    "maintenanceBanner": {
      "message": "We are currently undergoing some planned maintenance. You may experience issues as we finish up. We appreciate your patience."
    },
    "office": {
      "errors": {
        "unableToDelete": "Unable to delete office"
      }
    },
    "roles": {
      "assignRoles": "Assign Roles",
      "assignable": "Assignable",
      "errors": {
        "unableToDelete": "Unable to delete role!"
      },
      "success": {
        "roleDeleted": "Role deleted!"
      }
    },
    "search": {
      "placeholder": "Search"
    },
    "sidenav": {
      "adminMembers": "Admin Members",
      "candidateSearch": "Candidate Search",
      "consultantAssignments": "Consultant Assignments",
      "jobBoard": "Job Board",
      "jobResources": "Job Resources",
      "manageTags": "Manage Tags",
      "marketplace": "Marketplace",
      "myCourses": "My Courses",
      "offices": "Offices",
      "pendingRegistrations": "Pending Registrations",
      "pipelines": "Pipelines",
      "reporting": "Reporting",
      "rolesPermission": "Roles & Permissions",
      "servicePackages": "Service Packages",
      "skillspass": "Skillspass",
      "successfinder": "SuccessFinder",
      "support": "Support",
      "talentExchange": "Talent Exchange",
      "weeklyReports": "Weekly Reports"
    },
    "userRegistrationForm": {
      "account": "Account",
      "assignedConsultants": "ASSIGNED CONSULTANTS",
      "balanceMessage": "Balance cannot be changed once user has activated.",
      "confirmPassword": "CONFIRM PASSWORD",
      "confirmPasswordMessage": "Passwords must be 8-70 characters long and include: 1 uppercase, 1 lowercase, 1 digit and 1 special character.",
      "consultants": "CONSULTANTS",
      "createUserRegistration": "Create User Registration",
      "customer": "CUSTOMER",
      "customerProject": "CUSTOMER PROJECT - OPTIONAL",
      "dontSend": "DON'T SEND",
      "email": "EMAIL - OPTIONAL",
      "emailInviteMessage": "Please add the user's email address to select invite options.",
      "firstName": "FIRST NAME",
      "inviteEmail": "INVITE - EMAIL",
      "inviteSMS": "INVITE - SMS",
      "inviteSMSMessage": "Please add the user's phone number to select invite options.",
      "lastName": "LAST NAME",
      "marketplaceInfo": "Marketplace Info",
      "notes": "NOTES - OPTIONAL",
      "now": "NOW",
      "office": "OFFICE",
      "phone": "PHONE - OPTIONAL",
      "preferredLanguage": "PREFERRED LANGUAGE",
      "register": "REGISTER",
      "schedule": "SCHEDULE",
      "servicePackage": "SERVICE PACKAGE",
      "serviceStatus": "SERVICE STATUS - OPTIONAL",
      "startingDollarBalance": "STARTING DOLLAR BALANCE",
      "startingTokenBalance": "STARTING TOKEN BALANCE",
      "terms_of_service": "USER AGREES TO THE <TERMS OF SERVICE> AND <PRIVACY POLICY>.",
      "title": "TITLE - OPTIONAL",
      "tokens": "Tokens",
      "userDetails": "User Details",
      "userPassword": "USER PASSWORD",
      "userProfile": "USER PROFILE",
      "welcomePackage": "WELCOME PACKAGE"
    },
    "userRegistrations": {
      "errors": {
        "deleteFailed": "Delete attempt failed",
        "mergeFailed": "Merge failed",
        "notesNotSaved": "Notes not saved",
        "unableToApprove": "Unable to Approve User"
      },
      "success": {
        "inviteSent": "Approved! Invite sent to the user",
        "notesSaved": "Notes saved",
        "registrationsMerged": "Registrations merged"
      }
    },
    "users": {
      "accepted": "Accepted",
      "account": "Account",
      "accountActivated": "Account Activated",
      "accountDetails": "Account Details",
      "accountExpiresOn": "Account Expires On",
      "accountInfo": "Account Info",
      "accountNotActivated": "Account Not Activated",
      "actions": "Actions",
      "activated": "Activated",
      "activatedOn": "Activated On",
      "activations": "Activations",
      "activeApplications": "Active Applications",
      "activity": "Activity",
      "activityTimeline": "Activity Timeline",
      "activityTimelineModal": {
        "includeEndTime": "Please include end time",
        "includeStartTime": "Please include start time",
        "startTimeBeforeEndTime": "Start time must end before end time"
      },
      "activityType": "Activity Type",
      "add": "Add User",
      "addACity": "Add a City",
      "addANewUser": "Add a New User?",
      "addAProject": "Add a Project",
      "addActivity": "Add Activity",
      "addActivityType": "Add Activity Type",
      "addDocument": "Add Document",
      "addNewProject": "Add New Project",
      "addProvinceState": "Add a Province/State",
      "addedBy": "Added By",
      "address": "Address",
      "adminDetails": "Admin Details",
      "applied": "Applied",
      "appliedOn": "Applied On",
      "apply": "Apply",
      "assignedConsultants": "Assigned Consultants",
      "bookmark": "Bookmark",
      "bulkInviteService": "Bulk Invite Service",
      "careerProfile": "Career Profile",
      "careerPursuit": "Career Pursuit",
      "city": "City",
      "coachControl": "Coach Control",
      "coachControlModal": {
        "activeUser": "A new tab will open and you will have access to perform actions on this user's behalf.",
        "closeButton": "Close",
        "deactivatedUser": "Coach Control cannot be used because this user has been deactivated.",
        "disabledCoachControl": "This user has disabled coach control. Contact the user to update their account settings or reach out to our support for help",
        "disabledCoachControlLink": "here",
        "inactiveUser": "In order to use Coach Control, this user needs to be activated.",
        "submitButton": "Let's go!",
        "unconfirmedUser": "Coach Control cannot be used because this user has not confirmed their account."
      },
      "company": "Company",
      "companyInfo": "Company Info",
      "confirm": "Confirm",
      "confirmUser": "Confirm User",
      "confirmations": {
        "editUserNow": "Are you sure you want to save the user now?",
        "registerUserNow": "Are you sure you want to register the user now?",
        "saveUserNow": "⚠️ This user will not be invited. Are you sure you want to save the user without sending an invite now or scheduling an invite for later?",
        "scheduleInviteNow": "Are you sure you want to schedule an invite for the user now?",
        "sendInviteNow": "Are you sure you want to send the user an invite now?"
      },
      "consultants": "Consultants",
      "conversion": "Conversion",
      "country": "Country",
      "create": "Create User Registration",
      "createdAt": "Created At",
      "createdBy": "Created By",
      "createdOn": "Created On",
      "currentIndustry": "Current Industry",
      "currentPriority": "Current Priority",
      "customer": "Customer",
      "customerManagement": "Customer Management",
      "customerName": "Customer Name",
      "customerProject": "Customer Project",
      "date": "Date",
      "deactivateAccount": "Deactivate Account",
      "deactivateUserAccount": "Deactivate User Account",
      "deactivateUserAccountNote": "Deactivating a user's account will result in the user no longer having access to the platform. They will not be emailed or notified of this change.",
      "deactivateUserMessage": "Are you sure you want to deactivate this user?",
      "deleteSelected": "Delete Selected",
      "description": "Description",
      "details": "User Details",
      "documentModal": {
        "addDocument": "Add New Document",
        "noFileChosen": "No file chosen",
        "selectFile": "Select File To Upload",
        "shareDocument": "Share Document with User"
      },
      "documents": "Documents",
      "edit": "Edit User Registration",
      "editCustomer": "Edit Customer",
      "email": "Email",
      "emailMissing": "Please add the user's email address to select invite options",
      "employer": "Employer",
      "endTime": "End Time",
      "errors": {
        "cannotAssignConsultant": "Cannot assign consultant",
        "pleaseCompleteTheFollowingFields": "Please complete the following field(s):",
        "unableToConfirm": "Unable to Confirm User"
      },
      "excludeMetrics": "Exclude from Metrics on Dashboard",
      "excludedTagList": "Excluded Tag List",
      "export": "Export Users",
      "featureSwitchId": "Feature Switch ID",
      "fileName": "File Name",
      "firstName": "First Name",
      "flexTokenBalance": "Flex Token Balance",
      "followUp": "Follow Up",
      "hasNotAddedJobsYet": "Sorry, the user hasn't added any new jobs yet.",
      "idealNextIndustry": "Ideal Next Industry",
      "idealNextPosition": "Ideal Next Position",
      "idealStartDate": "Ideal Start Date",
      "import": "Import Users",
      "importModal": {
        "banner": {
          "heading": "The Import Users feature currently supports the Standard 1 Year Package and Flex Spend for adding multiple users.",
          "learnMore": "Learn More",
          "learnMoreLink": "https://portal-support.thrivemycareer.com/en/support/solutions/articles/14000134564-how-do-i-invite-multiple-users-at-once-",
          "paragraph": "For assistance with adding multiple users with other package types, please contact our support chat or add individual users ",
          "paragraphHere": "here",
          "support": "Support"
        },
        "bulkUploadFailed": "Bulk upload failed",
        "chooseFile": "Choose File",
        "close": "Close",
        "copy": "To upload your content, complete the following:",
        "defaultOffice": "Select a Default Office for these users.",
        "download": "Download the template below.",
        "downloadButton": "Download XLSX Template",
        "fillTemplate": "Fill out the template with your info.",
        "importFailed": "users failed to import.",
        "importResults": "Import Results",
        "importSuccess": "users successfully imported.",
        "importSummary": "Import Summary",
        "noFileChosen": "No file chosen",
        "pleaseSelectAFileToUpload": "Please select a file to upload",
        "pleaseUseProvidedTemplate": "Please use the provided template",
        "saveAndUpload": "Save your file, and upload it here:",
        "table": {
          "header": {
            "email": "Email",
            "name": "Name",
            "status": "Status"
          }
        },
        "uploadFailed": "Upload failed",
        "uploadSucceded": "Upload succeeded",
        "uploadSuccededWithProblems": "Upload succeeded with problems",
        "uploading": "Uploading",
        "userProfile": "Select a User Profile for these users.",
        "welcomePackage": "Select a Welcome Package for these users (Optional)"
      },
      "importTooltip": "Invite multiple users with the same package",
      "information": "User Information",
      "integrations": "Integrations",
      "interview": "Interview",
      "inviteEmail": "Invite - Email",
      "inviteExpiresAt": "Invite Expires At",
      "inviteExpiryInDays": "Invite Expiry in Days",
      "inviteSent": "Invite Sent",
      "inviteSms": "Invite - SMS",
      "inviteUrl": "Invite URL",
      "invitedBy": "Invited By",
      "jobTracker": "Job Tracker",
      "lastInviteSentAt": "Last Invite Sent At",
      "lastLogin": "Last Login",
      "lastName": "Last Name",
      "lastWeek": "Last Week",
      "learningCentre": "Learning Centre",
      "length": "Length",
      "lessonsCompleted": "Lessons Completed",
      "linkedInProfile": "LinkedIn Profile",
      "liveTokenBalance": "Live Token Balance",
      "location": "Where do you want to work?",
      "lookup": "User Lookup",
      "manageActivities": "Manage Activities",
      "manageActivityTypes": "Manage Activity Types",
      "manageDocuments": "Manage Documents",
      "marketplaceInfo": "Marketplace Info",
      "marketplaceInfoText": "Balance cannot be changed once user has activated.",
      "marketplaceModal": {
        "noServices": "No Services Found",
        "serviceStatusNotUpdated": "Unable to update status. Please try again later",
        "serviceStatusUpdated": "Service Status Updated",
        "table": {
          "accessedOn": "Accessed On",
          "completed": "Completed",
          "completedOn": "Completed On",
          "markedCompletedBy": "Marked Completed By",
          "purchasedOn": "Purchased On",
          "service": "Service"
        }
      },
      "mostRecentPosition": "Most Recent Position",
      "name": "Name",
      "noActivitiesFound": "No Activities Found",
      "noActivityTypesFound": "No Activity Types Found",
      "noAssignedConsultants": "No Assigned Consultant",
      "noDocumentsFound": "No Documents Found",
      "noEmailAvailable": "No Email Available",
      "noExpiryDate": "No Expiry Date",
      "noInviteSent": "No Invite Sent",
      "noJobApplicationsYet": "No Job Applications Yet",
      "noLessonsCompleted": "No Lessons Completed",
      "noMatchingUsersFound": "No matching users found.",
      "noNotes": "No Notes",
      "noPhoneAvailable": "No Phone Available",
      "noProject": "No Project",
      "noResumesYet": "No Resumes Yet",
      "noUsersFound": "No Users Found.",
      "nonProject": "None yet.",
      "noneAssigned": "None Assigned",
      "noneSelected": "None Selected",
      "noneSet": "None Set",
      "notActivated": "Not Activated",
      "notInvitedYet": "Not Invited Yet",
      "notRecorded": "Not Recorded",
      "notYetActivated": "Not Yet Activated",
      "notes": "Notes",
      "notesDescription": "Notes/Description",
      "numOfUsersDeleted": "user(s) deleted",
      "offerMade": "Offer Made",
      "office": "Office",
      "onboardingData": "Onboarding Data",
      "openToRemoteWork": "Open to remote work",
      "originalEmail": "Original Email",
      "personalInfo": "Personal Info",
      "phoneNumber": "Phone",
      "phoneNumberMissing": "Please add the user's phone number to select invite options.",
      "portalStatus": "Portal Status",
      "preferredLanguage": "Preferred Language",
      "projects": "Projects",
      "prospect": "Prospect",
      "provinceState": "Province/State",
      "registerUserNow": "Register User Now",
      "registrationDetails": "Registration Details",
      "remoteWork": "Open to remote work",
      "requestTooltip": "Invite multiple users with various packages; the Thrive Team will process and send invitations.",
      "research": "Research",
      "resumes": "Resumes",
      "revokeInvite": "Revoke Invite",
      "revokeInviteMessage": "Are you sure you want to revoke this user?",
      "sameAsMostRecentPosition": "Same as most recent position",
      "scheduleInvite": "Schedule Invite",
      "scheduleLabel": "Select day to send invite",
      "searchArchivedUsers": "Search Archived Users",
      "searchUsers": "Search Users",
      "sendInviteNow": "Send Invite Now",
      "seniority": "Seniority",
      "servicePackage": "Service Package",
      "serviceStatus": "Service Status",
      "serviceStatuses": {
        "active": "Active",
        "completed": "Completed",
        "declinedActivation": "Declined Activation",
        "neverStarted": "Never Started",
        "notYetStarted": "Not Yet Started"
      },
      "sharedWithUser": "Shared with User",
      "showServices": "Show Services",
      "standard1YearPackage": "Standard 1 Year Package",
      "startTime": "Start Time",
      "startingDollarBalance": "Starting Dollar Balance",
      "startingTokenBalance": "Starting Token Balance",
      "storeNumber": "Store Number",
      "storeRegion": "Store Region",
      "success": {
        "assignedConsultant": "Assigned Consultant",
        "copiedToClipboard": "Copied to Clipboard",
        "userConfirmed": "User Confirmed"
      },
      "summary": "Summary",
      "sureToSendInvite": "Are you sure you want to send an invite now",
      "table": {
        "header": {
          "confirmedAt": "Activated On",
          "createdAt": "Created On",
          "email": "Email",
          "firstName": "First Name",
          "lastName": "Last Name",
          "phoneNumber": "Phone",
          "projectName": "Project",
          "status": "Portal Status"
        },
        "item": {
          "resendInvite": "Resend Invite",
          "sendEmail": "Send Email",
          "sendInvite": "Send Invite",
          "sendSms": "Send SMS"
        }
      },
      "thisUserHasNotBeenActivated": "This user has not been activated.",
      "thisUserHasNotYetCreatedACareerProfile": "This user has not yet created a career profile.",
      "thisWeek": "This Week",
      "thriveStreams": "Thrive Streams",
      "title": "Title",
      "tokenBalance": "Token Balance",
      "type": "Type",
      "unableToBatchDelete": "Error: Unable to batch delete users",
      "unknown": "Unknown",
      "uploadedAt": "Uploaded At",
      "uploadedBy": "Uploaded By",
      "uploadedResume": "Uploaded Resume",
      "userActivity": "User Activity",
      "userAgrees": "User agrees to the",
      "userJobApplicationsInfo": "User Job Applications Info",
      "userNotYetActivated": "User Not Yet Activated",
      "userPassword": "User Password",
      "userPasswordConfirm": "Confirm Password",
      "userPasswordNote": "Passwords must be 8-70 characters long and include: 1 uppercase, 1 lowercase, 1 digit and 1 special character.",
      "userProfile": "User Profile",
      "userProfileExcludedTags": "Excluded Tags",
      "userRegistrations": "User Registrations",
      "usersInvited": "Users Invited",
      "viewDetails": "View Details",
      "weeklyAverage": "Weekly Average",
      "welcomePackage": "Welcome Package",
      "whatIsYourCurrentPriority": "What is your current priority?"
    }
  }], ["fr-ca", {
    "Danish": "Danois",
    "Dutch": "Néerlandais",
    "English": "English",
    "French": "Français",
    "German": "Allemand",
    "Norsk": "Norsk",
    "account": {
      "account": "Account Settings",
      "accountConfirmed": "Compte confirmé",
      "accountCreated": "Compte créé",
      "accountSettings": "Paramètres du compte",
      "adp": {
        "connectorCode": "Code du connecteur ADP",
        "connectorListingURL": "https://ca.apps.adp.com/en-CA/apps/442886",
        "integration": "Intégration ADP",
        "integrationDescription": "Intégrez votre compte ADP à Thrive pour rationaliser vos processus d'inscription d'utilisateurs.",
        "integrationLearnMore": "Cliquez ici pour en savoir plus."
      },
      "appVersion": "Version de l'application",
      "changeEmailAddress": "Changer l'adresse courriel",
      "changeEmailMsg": "Vous devrez vous connecter après avoir changé votre adresse e-mail.",
      "changePicture": "Changer la photo",
      "confirm": "Confirmer",
      "confirmNewPassword": "Confirmer le nouveau mot de passe",
      "confirmTotp": "3. Confirmer TOTP",
      "currentPassword": "Mot de passe actuel",
      "disable": "Désactiver",
      "disableMobileAppAuth": "Désactiver l'authentification des applications mobiles",
      "domains": "Domains",
      "enterTheSixDigit": "Entrez le code à six chiffres affiché par votre application d'authentification :",
      "firstName": "Prénom",
      "googleAuthenticator": "Authentificateur Google",
      "gravatarImagesMsg": "Nous utilisons des images Gravatar pour les photos de profil.",
      "installMobileAuth": "1. Installez l'application Mobile Authenticator",
      "lastName": "Nom de famille",
      "logInAgain": "Courriel mis à jour, vous devrez vous reconnecter",
      "logout": "Déconnexion",
      "mfaDisabled": "Authentification multi-facteur désactivée",
      "mfaEnabled": "Authentification multifactorielle activée",
      "microsoftAuthenticator": "Authentificateur Microsoft",
      "mobileAppAuthentication": "Application mobile d'authentification",
      "mustConfirmBeforeDisableMFA": "Pour désactiver l'authentification de l'application mobile, nous devons confirmer l'accès à votre compte",
      "newPassword": "Nouveau mot de passe",
      "orInputCode": "Ou saisissez manuellement ce code dans votre application d'authentification :",
      "password": "Mot de passe",
      "passwordMsg": "Les mots de passe doivent comporter entre 8 et 70 caractères et inclure : 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.",
      "passwordUpdated": "Mot de passe mis à jour",
      "phone": "Téléphoner",
      "portalSettings": "Paramètres du portail",
      "profile": "Profil",
      "profileNotUpdated": "Impossible de mettre à jour le profil",
      "profilePhoto": "Photo de profil",
      "profileUpdated": "Profil mis à jour",
      "scanQrCode": "2. Scannez le code QR avec l'application Authenticator",
      "secureYourAccountWithTotp": "Sécurisez votre compte avec l'authentification TOTP (Time-based One-time Password).",
      "securedWithTotp": "Votre compte est sécurisé par l'authentification TOTP (Time-based One-time Password).",
      "setup": "Installer",
      "setupMobileAppAuth": "Configurer l'application mobile d'authentification ",
      "systemSettings": "Paramètres du système",
      "twoFactorAuthentication": "Authentification à deux facteurs",
      "updateEmail": "Mettre à jour le courriel",
      "updatePassword": "Mettre à jour le mot de passe",
      "updateProfile": "Mettre à jour le profil",
      "updateYourEmail": "Mettez à jour votre e-mail",
      "willLogYouOut": "Cela vous déconnectera !",
      "youCanUseAnyTotp": "Vous pouvez utiliser n'importe quelle application compatible TOTP (Time-based One-time Password)"
    },
    "admins": {
      "addNewAdmin": "Ajouter un nouvel administrateur?",
      "deleteSubject": "Compte administrateur",
      "invite": "Inviter un administrateur",
      "inviteConfirmation": {
        "asAn": "en tant",
        "confirm": "Êtes-vous sûr de vouloir inviter",
        "toThe": "au portail client"
      },
      "modal": {
        "buttons": {
          "invite": "Inviter un administrateur",
          "save": "Sauvegarder l'administrateur"
        },
        "emailWillBeSent": "Une invitation par courriel sera envoyée pour inviter cet administrateur à créer un mot de passe.",
        "labels": {
          "authRole": "Rôle d'autorisation - Optionnel",
          "client": "Client - Optionnel",
          "email": "Email",
          "firstName": "Prénom",
          "lastName": "Nom de Famille",
          "locale": "Langue préférée",
          "office": "Bureau",
          "permissionLevel": "Niveau d'autorisation",
          "phoneNumber": "Numéro de téléphone - Optionnel",
          "position": "Poste - Optionnel",
          "profile": "Profil - Optionnel",
          "profileOptions": {
            "employer": "Employeur"
          },
          "required": "Requis"
        },
        "title": "Administrateur"
      },
      "noAdminsFound": "Aucun administrateur trouvé.",
      "search": "Recherche",
      "searchInputLabel": "Rechercher des administrateurs",
      "show": {
        "adminInfo": "Info administrateur",
        "breadcrumbs": {
          "adminLookup": "Recherche de l'administrateur",
          "manageAdmin": "Gérer l'administration"
        },
        "email": "Email",
        "featureSwitchId": "Fonctionnalité Switch ID",
        "name": "Nom",
        "table": {
          "headers": {
            "activatedOn": "Activé le",
            "createdOn": "Créé le",
            "customer": "Client",
            "email": "Email",
            "firstName": "Prénom",
            "lastName": "Nom de famille",
            "phoneNumber": "Téléphone",
            "portalStatus": "Statut du portail",
            "serviceStatus": "Statut du service"
          },
          "noResults": "Aucun utilisateur assigné.",
          "unknown": "Inconnu"
        },
        "title": "Coordonnées de l'administrateur"
      },
      "table": {
        "headers": {
          "authRole": "Rôle d'autorisation",
          "client": "Client",
          "confirmed": "Confirmé",
          "email": "Email",
          "name": "Nom",
          "permission": "Autorisation",
          "position": "Poste"
        },
        "resend": {
          "button": {
            "default": "Envoyer l'invitation de nouveau",
            "sending": "En cours d'envoi"
          },
          "confirmation": "Êtes-vous sûr de vouloir renvoyer une invitation à cet administrateur?"
        }
      },
      "title": "Membres administrateurs",
      "toast": {
        "failure": "L'invitation n'a pas été envoyée.",
        "success": "L'email d'invitation de l'administrateur a été renvoyé."
      }
    },
    "common": {
      "addNew": "Ajouter un nouveau",
      "and": "et",
      "cancel": "Annuler",
      "candidateSearch": "Recherche de candidats",
      "caseClosureDate": "Date de fermeture du dossier",
      "changesSaved": "Changements sauvegardés",
      "commonServiceDelivery": "Common Service Delivery (CSD)",
      "create": "Créer",
      "customers": "Clients",
      "dashboard": "Tableau de bord",
      "deactivate": "Désactiver",
      "deactivated": "Désactivé",
      "delete": "Supprimer",
      "deleted": "Supprimé",
      "doNotHavePermission": "Vous n'avez pas la permission de consulter cette page.",
      "dontSend": "Ne pas envoyer",
      "download": "Télécharger",
      "edit": "Modifier",
      "externalId": "External ID",
      "false": "Faux",
      "home": "Accueil",
      "in": "à",
      "incomeSupport": "Income Support (IS)",
      "loading": "Chargement",
      "madeWith": "Fabriqué avec",
      "manage": "Gérer",
      "na": "S/O",
      "new": "Nouveau",
      "no": "Non",
      "note": "Note",
      "notes": "Notes",
      "now": "Envoyer maintenant",
      "optional": "Optionnel",
      "passwordsMustMatch": "Les mots de passe doivent correspondre",
      "pleaseCompleteForm": "Veuillez remplir le formulaire",
      "privacyPolicy": "politique de confidentialité",
      "referredBy": "Référé par",
      "region": "Région",
      "register": "Enregistrer",
      "required": "Requis",
      "revoke": "Révoquer",
      "revoked": "Révoqué",
      "save": "Sauvegarder",
      "schedule": "Planifier",
      "search": "Rechercher",
      "somethingWentWrong": "Quelque chose a mal tourné",
      "source": "Source",
      "successfullyCreated": "Créé avec succès!",
      "successfullyUpdated": "Mis à jour avec succès!",
      "termsOfService": "conditions d'utilisation",
      "title": "Titre",
      "tokens": "Jetons",
      "true": "Véritable",
      "user": "Utilisateur",
      "users": "Utilisateurs",
      "view": "Voir",
      "yes": "Oui"
    },
    "customers": {
      "importUsers": "Importer des utilisateurs",
      "title": "Gestion des clients"
    },
    "dashboard": {
      "noData": {
        "addUsers": "Cliquez ici pour ajouter des utilisateurs",
        "description": "Les données des utilisateurs sont disponibles une fois que plus de 10 utilisateurs ont activé leurs comptes et ont été sur la plateforme pendant au moins deux semaines. Cela nous permet de préserver l'anonymat des données de transition de carrière des utilisateurs.",
        "header": "Vous n'avez pas encore de données utilisateur.",
        "supportText": "Pour toutes questions, écrivez à notre équipe d'assistance à l'adresse suivante"
      },
      "reporting": {
        "activatedUsers": {
          "action": "Utilisateurs activés",
          "title": "Activé",
          "tooltip": "Total des comptes utilisateurs activés au cours des 12 derniers mois. Le pourcentage indique l'évolution des activations d'un mois à l'autre."
        },
        "activeUsage": {
          "title": "Utilisateurs actifs"
        },
        "common": {
          "error": "Impossible de soumettre une demande!",
          "monthly": "Mensuel",
          "months": "Mois",
          "past": "Passé",
          "success": "Demande soumise!",
          "weekly": "Hebdomadaire"
        },
        "engagedUsers": {
          "action": "Utilisateurs engagés",
          "title": "Engagé",
          "tooltip": "Total des utilisateurs ayant utilisé la plateforme au cours des 30 derniers jours et ayant activé leur compte dans les premiers six mois de leur programme."
        },
        "invitedUsers": {
          "action": "Invitations envoyées",
          "title": "Invité",
          "tooltip": "Total des invitations envoyées au cours des 12 derniers mois.",
          "tooltipTitle": "Utilisateurs invités"
        },
        "jobResourcesUsed": {
          "noJobsSaved": "Aucun emploi sauvegardé avec SmartTracker pour le moment.",
          "title": "Ressources utilisées pour la recherche d'emploi"
        },
        "netPromoterScore": {
          "tooltip": " La moyenne du secteur est de 20. Le taux de recommandation net représente le pourcentage d'utilisateurs qui recommandent Thrive à un score de 9 ou 10, moins le pourcentage d'utilisateurs qui ont offert un score de 6 ou moins sur une échelle de 0 à 10.",
          "tooltipHere": "ici",
          "tooltipTitle": "Taux de recommandation net"
        },
        "nps": "TRN (NPS)",
        "officeMetrics": {
          "accounts": "Comptes",
          "accountsDescription": "Nombre total d'enregistrements d'utilisateurs en continu",
          "activated": "Activé",
          "activatedDescription": "Comptes d'utilisateurs actifs",
          "conversion": "Conversion",
          "conversionDescription": "Comptes activés / Invitations envoyées",
          "invited": "Invité",
          "invitedDescription": "Envoi d'invitations par courriel",
          "title": "Inscriptions des utilisateurs"
        },
        "onboardedUsers": {
          "action": "Utilisateurs intégrés",
          "title": "Intégré",
          "tooltip": "Nombre total d'utilisateurs qui ont bénéficié d'une rencontre d'intégration au cours des 12 derniers mois. Ces utilisateurs ont activé leur compte et ont bénéficié d'au moins un service."
        },
        "pendingUserRegistrations": {
          "noPendingUsers": "Aucun utilisateur en attente n'a été trouvé. Excellent travail!",
          "title": "Enregistrements d'utilisateurs en attente",
          "viewFullPage": "Voir la page complète"
        },
        "pieChart": {
          "title": "Priorité observé des utilisateurs en transition",
          "tooltip": "Priorité observé des utilisateurs en transition au cours des 90 derniers jours.",
          "tooltipTitle": "Priorité observée chez les utilisateurs en transition"
        },
        "recentJobResources": {
          "title": "Ressources d'emploi récemment ajoutées"
        },
        "requestCustomReport": {
          "description": "Veuillez remplir le formulaire ci-dessous pour demander un rapport personnalisé à notre équipe d'assistance. Nous sommes heureux de vous aider et sommes en mesure de répondre à la plupart des demandes en quelques heures. Un membre de notre équipe vous contactera dans un délai d'un jour ouvrable.",
          "reportRequest": "Demande de rapport",
          "reportRequestDescription": "Veuillez être aussi précis que possible sur les données et le format que vous recherchez.",
          "reportTitle": "Titre du rapport",
          "submit": "Soumettre une demande de rapport personnalisé",
          "subtitle": "Vous cherchez autre chose?",
          "title": "Demander un rapport personnalisé"
        },
        "requestOnsiteSupport": {
          "careerTransitionProgramLabel": "Programme de transition de carrière proposé à l’employé",
          "dateAndTimeLabel": "Date et heure du soutien",
          "description": "Veuillez remplir le formulaire ci-dessous pour demander un soutien sur place au moment de l'annonce. Un membre de notre équipe vous contactera dans un délai d'un jour ouvrable. Veuillez noter qu'il y a des coûts associés au soutien sur place.",
          "employeeNameLabel": "Nom de l'employé",
          "hrNameLabel": "Nom du contact HR",
          "hrOnsiteNameLabel": "Contact RH à demander à l'arrivée",
          "hrPhoneNumberLabel": "Numéro de téléphone du contact RH",
          "jobTitleLabel": "Titre du poste",
          "notesDescription": "Veuillez fournir autant d'informations que possible sur le soutien sur place requis.",
          "notesLabel": "Notes",
          "reasonForDepartureLabel": "Motif du départ",
          "siteAddressLabel": "Adresse du site",
          "submit": "Soumettre une demande de soutien sur place",
          "subtitle": "Vous avez besoin d'aide lors de l'annonce aux employés visés?",
          "tenureLabel": "Ancienneté dans le poste",
          "title": "Demander un soutien sur place",
          "virtualOrInPersonLabel": "Soutien virtuel ou en personne"
        },
        "spendingAndDeposits": {
          "deposits": "Dépôts",
          "depositsLastYear": "Montant investi au cours de la dernière année",
          "spending": "Dépenses",
          "spendingLastYear": "Dépenses dans la dernière année"
        },
        "stackedLineChart": {
          "title": "Dépenses par catégorie",
          "tooltip": "Dépenses par catégorie pour chaque mois, en pourcentage des dépenses totales."
        },
        "teamMembers": {
          "email": "E-mail",
          "setting": "Réglage",
          "title": "Membres de l'équipe"
        },
        "transitionedUsers": {
          "action": "Utilisateurs replacés",
          "title": "Replacé",
          "tooltip": "Total des utilisateurs ayant réalisé leur transition vers un nouvel emploi, un retour aux études, un démarrage d'entreprise, la retraite au cours des 12 derniers mois."
        },
        "userTestimonials": {
          "subtitle": "Nous recueillons en permanence la rétroaction de nos utilisateurs afin de continuer à améliorer les services que nous offrons",
          "title": "Écoutez ce que disent les utilisateurs de Thrive!"
        }
      }
    },
    "dropdowns": {
      "delete": {
        "confirmation": "Êtes-vous sûr de vouloir supprimer ceci"
      }
    },
    "fourOhFour": {
      "button": {
        "backToDashboard": "Retour au tableau de bord",
        "backToHome": "Retour à l'accueil"
      },
      "errors": {
        "contactSupport": "Veuillez réessayer plus tard ou contacter le service d'assistance pour obtenir de l'aide.",
        "invalidOptions": "Oups ! Il semble qu'il n'y ait pas d'options disponibles pour vous en ce moment.",
        "lostMessage": "Oups ! On dirait que vous êtes un peu perdu..."
      },
      "title": "404 Page non trouvée"
    },
    "incidentBanner": {
      "message": "Nous rencontrons actuellement des problèmes de performance. Notre équipe en est consciente et nous vous remercions de votre patience pendant que nous travaillons à la résolution de ces problèmes."
    },
    "jobBoard": {
      "index": {
        "addJobPostBtn": "Ajouter à l'offre d'emploi",
        "companyName": "Nom de l'entreprise",
        "header": "Tableau d'emploi",
        "jobTitle": "Titre d'emploi",
        "noJobPostingsBtn": "Cliquez ici pour créer une offre d'emploi",
        "noJobPostingsHeader": "Vous n'avez pas d'offres d'emploi.",
        "noJobPostingsParagraph": "Les données relatives aux offres d'emploi seront disponibles une fois qu'elles auront été créées. Si vous avez des questions, envoyez un courriel à notre équipe d'assistance à la clientèle à l'adresse suivante ",
        "noMatchingJobPosts": "Aucun emploi correspondant n'a été trouvé.",
        "notPublished": "Non publié",
        "pageTitle": "Tableau d'emploi",
        "searchPlaceholder": "Recherche par titre de poste ou nom d'entreprise",
        "statusInactive": "Inactif",
        "statusPublished": "Publié",
        "tableHeadings": {
          "companyName": "Nom de l'entreprise",
          "jobTitle": "Titre d'emploi",
          "publishedDate": "Date de publication",
          "status": "Statut"
        }
      },
      "jobPostEditor": {
        "breadcrumbs": {
          "edit": "Modifier",
          "index": "Offres d'emploi",
          "new": "Nouveau"
        },
        "description": "Publiez une offre d’emploi sur Thrive où se trouvent des demandeurs d’emploi actifs et qualifiés. Votre publication sera étiquetée comme <span class=\"Thrive-text font-weight-bold\">« Opportunité de développement »</span> pour un engagement plus élevé.",
        "formFields": {
          "applicationDeadline": "La date limite de dépôt des candidatures est fixée à ",
          "companyName": "Nom de l'entreprise",
          "employmentType": "Type d'emploi",
          "footer": "Les opportunités Thrive sont des offres d'emploi d'employeurs travaillant avec Thrive qui cherchent activement à pourvoir des postes.",
          "jobApplicationUrl": "Url de demande d'emploi",
          "jobDescription": "Description de l'emploi",
          "jobDescriptionSubtext": "Décrivez les responsabilités de cet emploi, l'expérience de travail requise, les compétences ou la formation :",
          "jobTitle": "Titre d'emploi",
          "location": "Emplacement",
          "locationPlaceholder": "Saisir un emplacement",
          "notPublished": "Pas encore publié",
          "organizationClient": "Organisation Cliente",
          "publishLater": "Publier plus tard",
          "publishNow": "Publier maintenant",
          "publishTitle": "Quand voulez-vous publier?",
          "salary": "Salaire",
          "salaryOptional": "Salaire - Optionnel",
          "saveJob": "Enregistrer dans l'outil de repérage des emplois",
          "selectPolicy": "Sélectionner une politique",
          "selectType": "Sélectionner le type",
          "viewDetails": "Voir les détails",
          "workplacePolicy": "Politique sur le lieu de travail",
          "workplacePolicySubtext": "Ce travail peut-il être effectué à distance ? Est-ce un environnement de travail hybride?"
        },
        "jobTypes": {
          "apprenticeship": "Apprentissage",
          "casual": "Occasionnel",
          "comission": "Commission",
          "commission": "Commission",
          "contract": "Contracter",
          "freelance": "Free-lance",
          "fullTime": "À temps plein",
          "full_time": "À temps plein",
          "internship": "Stage",
          "partTime": "À temps partiel",
          "part_time": "À temps partiel",
          "permanent": "Permanent",
          "subcontract": "Free-lance",
          "temporary": "Temporaire",
          "volunteer": "Bénévole"
        },
        "jobWorkplacePolicies": {
          "hybrid": "Hybride",
          "onsite": "Sur site",
          "remote": "Télécommande"
        },
        "opporunity_svg": "/assets/images/jobposts/ThriveOpportunityFr.svg",
        "placeholderPost": "<h3><u>Description de l'emploi</u></h3>La description de poste prépare le terrain pour le reste de votre poste. Les candidats parcourent des centaines d'offres d'emploi et le résumé du poste peut être la seule section qu'ils lisent avant de décider de poursuivre leur lecture.<h3><u>Responsabilités</u></h3><ul><li>Objet 1</li><li>Article 2</li></ul><h3><u>Qualifications</u></h3><ul><li>Objet 1</li><li>Article 2</li><li>Article 3</li></ul><h3><u>Description de l'entreprise</u></h3>Ajoutez des détails sur les valeurs de votre entreprise, son historique et son énoncé de mission. Un court argumentaire de l’entreprise peut aider à attirer des candidats qualifiés.",
        "title": "Éditeur d'offres d'emploi"
      },
      "jobPostSettings": {
        "createdOn": "Créé le",
        "deleteJobPost": "Supprimer l'offre d'emploi",
        "editJobPost": "Modifier l'offre d'emploi",
        "jobPostPreview": "Aperçu de l'offre d'emploi",
        "notPublished": "Pas encore publié",
        "publish": "Publier",
        "publishedDate": "Date publiée",
        "status": "Statut",
        "statusInactive": "Inactif",
        "statusPublished": "Publié",
        "title": "Paramètres de l'offre d'emploi",
        "unpublish": "Dépublier"
      }
    },
    "login": {
      "about": "À propos",
      "authCodeTitle": "Entrez le code à six chiffres de votre application d'authentification",
      "careerJsm": "CareerJSM",
      "contact": "Contact",
      "createAccount": "Créer un compte",
      "createHubAccount": "Créer un compte administrateur HUB",
      "downLoadChrome": " Télécharger Google Chrome",
      "emailAssistance": "Vous n'avez pas reçu de code après plusieurs tentatives ?",
      "emailAuthCodeTitle": "Entrez le code à 6 chiffres que nous vous avons envoyé par courriel",
      "enterANewPassword": "Entrer un nouveau mot de passe",
      "errors": {
        "sso-token": {
          "invalid": "Jeton SSO non valide, veuillez contacter le support",
          "missing": "Aucun jeton SSO trouvé, veuillez contacter le support"
        }
      },
      "forAssistance": "pour l'assistance",
      "forgotPassword": "Mot de passe oublié?",
      "hide": "Cacher",
      "hubTitle1": "Le HUB est le tableau de bord d'administration.",
      "hubTitle2": "Ici, vous pouvez gérer vos clients, vos utilisateurs et vos données.",
      "iAgreeTerms1": "Je suis d'accord avec le",
      "iAgreeTerms2": "conditions d'utilisation",
      "iAgreeTerms3": "et",
      "iAgreeTerms4": "politique de confidentialité.",
      "invalidEmailOrPW": "Email ou mot de passe invalide",
      "login": "Connexion",
      "lostAuthCodeApp": "Application d'authentification perdue ?",
      "notYou": "Ce n'est pas vous? Cliquez ici.",
      "password": "Mot de passe",
      "phoneNumber": "Numéro de téléphone",
      "positionTitle": "Poste/Titre",
      "poweredBy": "Alimenté par",
      "privacyPolicy": "Politique de confidentialité",
      "recovery1": "S'il vous plaît contactez ",
      "recovery2": "pour commencer la récupération de compte.",
      "resend": "Renvoyer",
      "resendVerify": "Vous n'avez pas reçu le code ? ",
      "resetInstructions1": "Entrez votre ",
      "resetInstructions2": "adresse e-mail",
      "resetInstructions3": " pour recevoir les instructions de réinitialisation du mot de passe :",
      "resetPassword": "Réinitialiser le mot de passe",
      "show": "AFFICHER",
      "submit": "Soumettre",
      "support": "Soutien",
      "termsOfUse": "Conditions d'utilisation",
      "title": "Se connecter au HUB d'administration",
      "useChromeMsg": "Vous n'utilisez pas Chrome. Veuillez télécharger ou changer de navigateur pour une meilleure expérience."
    },
    "maintenanceBanner": {
      "message": "Nous procédons actuellement à une maintenance planifiée. Il se peut que vous rencontriez des problèmes pendant que nous terminons. Nous vous remercions de votre patience."
    },
    "office": {
      "errors": {
        "unableToDelete": "Impossible de supprimer le bureau"
      }
    },
    "roles": {
      "assignRoles": "Attribuer des rôles",
      "assignable": "Attribuable",
      "errors": {
        "unableToDelete": "Impossible de supprimer le rôle!"
      },
      "success": {
        "roleDeleted": "Rôle supprimé!"
      }
    },
    "search": {
      "placeholder": "Recherche"
    },
    "sidenav": {
      "adminMembers": "Administrateurs",
      "candidateSearch": "Recherche de candidats",
      "consultantAssignments": "Missions des consultants",
      "jobBoard": "Tableau d'emploi",
      "jobResources": "Ressources pour l'emploi",
      "manageTags": "Gérer les tags",
      "marketplace": "Marché",
      "myCourses": "Mes cours",
      "offices": "Bureaux",
      "pendingRegistrations": "Enregistrements en attente",
      "pipelines": "Pipelines",
      "reporting": "Reporting",
      "rolesPermission": "Rôles et autorisations",
      "servicePackages": "Offres de services",
      "skillspass": "Skillspass",
      "successfinder": "SuccessFinder",
      "support": "Soutien",
      "talentExchange": "l’Échange de Talents",
      "weeklyReports": "Rapports hebdomadaires"
    },
    "userRegistrationForm": {
      "account": "Compte",
      "assignedConsultants": "CONSULTANTS ASSIGNÉS",
      "balanceMessage": "Le solde ne peut être modifié une fois l'utilisateur activé.",
      "confirmPassword": "CONFIRMER LE MOT DE PASSE",
      "confirmPasswordMessage": "Les mots de passe doivent comporter de 8 à 70 caractères et inclure : 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.",
      "consultants": "CONSULTANTS",
      "createUserRegistration": "Créer un enregistrement d'utilisateur",
      "customer": "CLIENT",
      "customerProject": "PROJET CLIENT - FACULTATIF",
      "dontSend": "N'ENVOYEZ PAS",
      "email": "E-MAIL - FACULTATIF",
      "emailInviteMessage": "Veuillez ajouter l'adresse électronique de l'utilisateur pour sélectionner les options d'invitation.",
      "firstName": "PRÉNOM",
      "inviteEmail": "INVITE - EMAIL",
      "inviteSMS": "INVITE - SMS",
      "inviteSMSMessage": "Veuillez ajouter le numéro de téléphone de l'utilisateur pour sélectionner les options d'invitation.",
      "lastName": "NOM DE FAMILLE",
      "marketplaceInfo": "Informations sur le marché",
      "notes": "NOTES - FACULTATIF",
      "now": "MAINTENANT",
      "office": "BUREAU",
      "phone": "TÉLÉPHONE - FACULTATIF",
      "preferredLanguage": "LANGUE PRÉFÉRÉE",
      "register": "ENREGISTRER",
      "schedule": "SCHEDULE",
      "servicePackage": "PAQUET DE SERVICES",
      "serviceStatus": "STATUT DU SERVICE - FACULTATIF",
      "startingDollarBalance": "SOLDE DE DÉPART EN DOLLARS",
      "startingTokenBalance": "SOLDE DE DÉPART DU JETON",
      "terms_of_service": "L'UTILISATEUR ACCEPTE LES <TERMS OF SERVICE> ET LA <PRIVACY POLICY>.",
      "title": "TITRE - FACULTATIF",
      "tokens": "Jetons",
      "userDetails": "Détails de l'utilisateur",
      "userPassword": "MOT DE PASSE DE L'UTILISATEUR",
      "userProfile": "PROFIL DE L'UTILISATEUR",
      "welcomePackage": "KIT DE BIENVENUE"
    },
    "userRegistrations": {
      "errors": {
        "deleteFailed": "La tentative de suppression a échoué",
        "mergeFailed": "La fusion a échoué",
        "notesNotSaved": "Notes non sauvegardées",
        "unableToApprove": "Impossible d'approuver l'utilisateur"
      },
      "success": {
        "inviteSent": "Approuvé! Invitation envoyée à l'utilisateur",
        "notesSaved": "Notes enregistrées",
        "registrationsMerged": "Inscriptions fusionnées"
      }
    },
    "users": {
      "accepted": "Accepté",
      "account": "Compte",
      "accountActivated": "Compte activé",
      "accountDetails": "Détails du compte",
      "accountExpiresOn": "Le compte expire le",
      "accountInfo": "Informations sur le compte",
      "accountNotActivated": "Compte non activé",
      "actions": "Actions",
      "activated": "Activé ",
      "activatedOn": "Activé le",
      "activations": "Activations",
      "activeApplications": "Applications actives",
      "activity": "Activité",
      "activityTimeline": "Calendrier des activités",
      "activityTimelineModal": {
        "includeEndTime": "Veuillez inclure l'heure de fin",
        "includeStartTime": "Veuillez inclure l'heure de début",
        "startTimeBeforeEndTime": "L'heure de début doit se terminer avant l'heure de fin"
      },
      "activityType": "Type d'activité",
      "add": "Ajouter un utilisateur",
      "addACity": "Ajouter une ville",
      "addANewUser": "Ajouter un nouvel utilisateur?",
      "addAProject": "Ajouter un projet",
      "addActivity": "Ajouter une activité",
      "addActivityType": "Ajouter un type d'activité",
      "addDocument": "Ajouter un document",
      "addNewProject": "Ajouter un nouveau projet",
      "addProvinceState": "Ajouter une province/un état",
      "addedBy": "Ajouté par",
      "address": "Adresse",
      "adminDetails": "Détails administratifs",
      "applied": "Appliqué",
      "appliedOn": "Appliqué le",
      "apply": "Appliquer",
      "assignedConsultants": "Consultants assignés",
      "bookmark": "Signet",
      "bulkInviteService": "Service d'invitations en masse",
      "careerProfile": "Profil de carrière",
      "careerPursuit": "Poursuite de la carrière",
      "city": "Ville",
      "coachControl": "Contrôle par le coach",
      "coachControlModal": {
        "activeUser": "Un nouvel onglet s'ouvrira et vous aurez accès à des actions au nom de cet utilisateur.",
        "closeButton": "Fermer",
        "deactivatedUser": "Le contrôle par le coach ne peut pas être utilisé car cet utilisateur a été désactivé.",
        "disabledCoachControl": "Cet utilisateur a désactivé le contrôle des entraîneurs. Contactez l'utilisateur pour mettre à jour les paramètres de son compte ou contactez notre service d'assistance pour obtenir de l'aide.",
        "disabledCoachControlLink": "ici",
        "inactiveUser": "Pour pouvoir utiliser le Coach Control, cet utilisateur doit être activé.",
        "submitButton": "Allons-y!",
        "unconfirmedUser": "Le contrôle par le coach ne peut pas être utilisé car cet utilisateur n'a pas confirmé son compte."
      },
      "company": "Entreprise",
      "companyInfo": "Informations sur l'entreprise",
      "confirm": "Confirmer",
      "confirmUser": "Confirmer l'utilisateur",
      "confirmations": {
        "editUserNow": "Êtes-vous sûr de vouloir sauvegarder l'utilisateur maintenant?",
        "registerUserNow": "Êtes-vous sûr de vouloir inscrire l'utilisateur maintenant?",
        "saveUserNow": "⚠️ Cet utilisateur ne sera pas invité. Êtes-vous sûr de vouloir enregistrer l'utilisateur sans envoyer l'invitation maintenant?",
        "scheduleInviteNow": "Êtes-vous sûr de vouloir programmer une invitation pour l'utilisateur maintenant?",
        "sendInviteNow": "Êtes-vous sûr de vouloir envoyer une invitation à l'utilisateur maintenant?"
      },
      "consultants": "Consultants",
      "conversion": "Conversion",
      "country": "Pays",
      "create": "Créer un enregistrement d'utilisateur",
      "createdAt": "Créé à",
      "createdBy": "Créé par",
      "createdOn": "Créé le",
      "currentIndustry": "Industrie actuelle",
      "currentPriority": "Priorité actuelle",
      "customer": "Client",
      "customerManagement": "Gestion des clients",
      "customerName": "Nom du client",
      "customerProject": "Projet client",
      "date": "Date",
      "deactivateAccount": "Désactiver le compte",
      "deactivateUserAccount": "Désactiver le compte utilisateur",
      "deactivateUserAccountNote": "En désactivant le compte d'un utilisateur, celui-ci n'aura plus accès à la plateforme. Il ne recevra pas de courriel ni de notification de ce changement.",
      "deactivateUserMessage": "Êtes-vous sûr de vouloir désactiver cet utilisateur?",
      "deleteSelected": "Supprimer la sélection",
      "description": "Description",
      "details": "Détails de l'utilisateur",
      "documentModal": {
        "addDocument": "Ajouter un nouveau document",
        "noFileChosen": "Aucun fichier choisi",
        "selectFile": "Sélectionner le fichier à télécharger",
        "shareDocument": "Partager le document avec l'utilisateur"
      },
      "documents": "Documents",
      "edit": "Modifier l'inscription de l'utilisateur",
      "editCustomer": "Modifier le client",
      "email": "Courriel",
      "emailMissing": "Veuillez ajouter l'adresse électronique de l'utilisateur pour sélectionner les options d'invitation",
      "employer": "Employeur",
      "endTime": "Fin du temps",
      "errors": {
        "cannotAssignConsultant": "Impossible d'assigner un consultant",
        "pleaseCompleteTheFollowingFields": "Veuillez remplir le(s) champ(s) suivant(s):",
        "unableToConfirm": "Impossible de confirmer l'utilisateur"
      },
      "excludeMetrics": "Exclure des données du tableau de bord",
      "excludedTagList": "Liste des étiquettes exclues",
      "export": "Exporter des utilisateurs",
      "featureSwitchId": "Fonctionnalité Switch ID",
      "fileName": "Nom du fichier",
      "firstName": "Prénom",
      "flexTokenBalance": "Balance de jetons Flex",
      "followUp": "Suivi",
      "hasNotAddedJobsYet": "Désolé, l'utilisateur n'a pas encore ajouté de nouveaux emplois.",
      "idealNextIndustry": "Industrie suivante idéale",
      "idealNextPosition": "Prochain poste idéal",
      "idealStartDate": "Date de début idéale",
      "import": "Importer des utilisateurs",
      "importModal": {
        "banner": {
          "heading": "Cette fonctionnalité, spécifique à l’importation de plusieurs utilisateurs, supporte actuellement uniquement le programme ‘Forfait de bienvenue + Jetons flex‘.",
          "learnMore": "En savoir plus",
          "learnMoreLink": "https://portal-support.thrivemycareer.com/fr/support/solutions/articles/14000134564-comment-puis-je-inviter-plusieurs-utilisateurs-%C3%A0-la-fois-",
          "paragraph": "Pour obtenir de l’aide sur l’ajout d’un groupe d’utilisateurs ayant d’autre type de programme, merci de nous contacter via le chat d’assistance ou d’ajouter individuellement les utilisateurs ",
          "paragraphHere": "ici",
          "support": "Soutien"
        },
        "bulkUploadFailed": "Le téléchargement en masse a échoué",
        "chooseFile": "Choisir le fichier",
        "close": "Fermer",
        "copy": "Pour télécharger votre contenu, complétez ce qui suit:",
        "defaultOffice": "Sélectionnez un bureau par défaut pour ces utilisateurs.",
        "download": "Téléchargez le modèle ci-dessous.",
        "downloadButton": "Télécharger le modèle XLSX",
        "fillTemplate": "Remplissez le modèle avec vos informations.",
        "importFailed": "utilisateurs n'ont pas réussi à s'importer.",
        "importResults": "Importer les résultats",
        "importSuccess": "utilisateurs importés avec succès.",
        "importSummary": "Récapitulatif d'importation",
        "noFileChosen": "Aucun fichier choisi",
        "pleaseSelectAFileToUpload": "Veuillez sélectionner un fichier à télécharger",
        "pleaseUseProvidedTemplate": "Veuillez utiliser le modèle fourni",
        "saveAndUpload": "Enregistrez votre fichier et téléchargez-le ici:",
        "table": {
          "header": {
            "email": "Courriel",
            "name": "Nom",
            "status": "Statut"
          }
        },
        "uploadFailed": "Échec du téléchargement",
        "uploadSucceded": "Téléchargement réussi",
        "uploadSuccededWithProblems": "Le téléchargement a réussi avec des problèmes",
        "uploading": "En téléchargement",
        "userProfile": "Sélectionner un profil utilisateur pour ces utilisateurs.",
        "welcomePackage": "Sélectionnez un forfait de bienvenue pour ces utilisateurs (facultatif)"
      },
      "importTooltip": "Invitez plusieurs utilisateurs avec le même paquet.",
      "information": "Informations sur l'utilisateur",
      "integrations": "Intégrations",
      "interview": "Entrevue",
      "inviteEmail": "Invitation - Courriel",
      "inviteExpiresAt": "L'invitation expire à",
      "inviteExpiryInDays": "ENombre de jours restant avant l'expiration de l'invitation",
      "inviteSent": "Invitation envoyée",
      "inviteSms": "Inviter - SMS",
      "inviteUrl": "URL de l'invitation",
      "invitedBy": "Invité par",
      "jobTracker": "Repérage d'emploi",
      "lastInviteSentAt": "Dernière invitation envoyée à",
      "lastLogin": "Dernière connexion",
      "lastName": "Nom de famille",
      "lastWeek": "La semaine dernière",
      "learningCentre": "Centre d'apprentissage",
      "length": "Durée",
      "lessonsCompleted": "Leçons terminées",
      "linkedInProfile": "Profil LinkedIn",
      "liveTokenBalance": "Balance actuelle de jetons",
      "location": "Où voulez-vous travailler?",
      "lookup": "Recherche d'un utilisateur",
      "manageActivities": "Gérer les activités",
      "manageActivityTypes": "Gérer les types d'activité",
      "manageDocuments": "Gérer les documents",
      "marketplaceInfo": "Information sur les programmes offert au participants",
      "marketplaceInfoText": "Le solde ne peut être modifié une fois l'utilisateur activé.",
      "marketplaceModal": {
        "noServices": "Aucun service trouvé",
        "serviceStatusNotUpdated": "Impossible de mettre à jour le statut. Veuillez réessayer plus tard",
        "serviceStatusUpdated": "Mise à jour de l'état des services",
        "table": {
          "accessedOn": "Consulté le",
          "completed": "Complété",
          "completedOn": "Complété le",
          "markedCompletedBy": "Marqué complété par",
          "purchasedOn": "Acheté le",
          "service": "Service"
        }
      },
      "mostRecentPosition": "Poste le plus récent",
      "name": "Nom",
      "noActivitiesFound": "Aucune activité trouvée",
      "noActivityTypesFound": "Aucun type d'activité trouvé",
      "noAssignedConsultants": "Aucun consultant assigné",
      "noDocumentsFound": "Aucun document trouvé",
      "noEmailAvailable": "Aucun courriel disponible",
      "noExpiryDate": "Aucune date d'expiration",
      "noInviteSent": "Aucune invitation envoyée",
      "noJobApplicationsYet": "Pas encore de demandes d'emploi",
      "noLessonsCompleted": "Aucune leçon terminée",
      "noMatchingUsersFound": "Aucun utilisateur correspondant trouvé.",
      "noNotes": "Aucune note",
      "noPhoneAvailable": "Pas de numéro de téléphone disponible",
      "noProject": "Pas de projet",
      "noResumesYet": "Pas encore de CV",
      "noUsersFound": "Aucun utilisateur trouvé.",
      "nonProject": "Aucun encore.",
      "noneAssigned": "Aucune assignée",
      "noneSelected": "Aucun sélectionné",
      "noneSet": "Aucun en place",
      "notActivated": "Non activé",
      "notInvitedYet": "Pas encore invité",
      "notRecorded": "Non enregistré",
      "notYetActivated": "Pas encore activé",
      "notes": "Notes",
      "notesDescription": "Notes/Description",
      "numOfUsersDeleted": "utilisateur(s) supprimé(s)",
      "offerMade": "Offre faite",
      "office": "Bureau",
      "onboardingData": "Données d'embarquement",
      "openToRemoteWork": "Ouvert au travail à distance",
      "originalEmail": "Courriel original",
      "personalInfo": "Renseignements personnels",
      "phoneNumber": "Téléphone",
      "phoneNumberMissing": "Veuillez ajouter le numéro de téléphone de l'utilisateur pour sélectionner les options d'invitation.",
      "portalStatus": "Statut du portail",
      "preferredLanguage": "Langue préférée",
      "projects": "Projets",
      "prospect": "Prospect",
      "provinceState": "Province/État",
      "registerUserNow": "Inscrire l'utilisateur maintenant",
      "registrationDetails": "Détails de l'inscription",
      "remoteWork": "Ouvert au travail à distance",
      "requestTooltip": "Invitez plusieurs utilisateurs avec différents paquets ; l'équipe Thrive traitera et enverra les invitations.",
      "research": "Recherche",
      "resumes": "C.V.",
      "revokeInvite": "Révoquer l'invitation",
      "revokeInviteMessage": "Êtes-vous sûr de vouloir révoquer cet utilisateur?",
      "sameAsMostRecentPosition": "Identique au poste le plus récent",
      "scheduleInvite": "Programmer l'invitation",
      "scheduleLabel": "Sélectionner le jour de l'envoi de l'invitation",
      "searchArchivedUsers": "Search Archived Users",
      "searchUsers": "Recherche d'utilisateurs",
      "sendInviteNow": "Envoyer l'invitation maintenant",
      "seniority": "Ancienneté",
      "servicePackage": "Forfait de services",
      "serviceStatus": "État du service",
      "serviceStatuses": {
        "active": "Actif",
        "completed": "Complété",
        "declinedActivation": "Activation refusée",
        "neverStarted": "Jamais commencé",
        "notYetStarted": "Pas encore commencé"
      },
      "sharedWithUser": "Partagé avec l'utilisateur",
      "showServices": "Montrer les services",
      "standard1YearPackage": "Accès standard d'un an",
      "startTime": "Heure de début",
      "startingDollarBalance": "Solde initial en dollars",
      "startingTokenBalance": "Solde initial en jetons",
      "storeNumber": "Numéro de magasin",
      "storeRegion": "Région du magasin",
      "success": {
        "assignedConsultant": "Consultant assigné",
        "copiedToClipboard": "Copié dans le presse-papiers",
        "userConfirmed": "Utilisateur confirmé"
      },
      "summary": "Sommaire",
      "sureToSendInvite": "Êtes-vous sûr de vouloir envoyer une invitation maintenant ?",
      "table": {
        "header": {
          "confirmedAt": "Activé le",
          "createdAt": "Créé le",
          "email": "Courriel",
          "firstName": "Prénom",
          "lastName": "Nom de famille",
          "phoneNumber": "Téléphone",
          "projectName": "Projet",
          "status": "Statut du portail"
        },
        "item": {
          "resendInvite": "Envoyer l'invitation de nouveau",
          "sendEmail": "Envoyer un courriel",
          "sendInvite": "Envoyer une invitation",
          "sendSms": "Envoyer un SMS"
        }
      },
      "thisUserHasNotBeenActivated": "Cet utilisateur n'a pas été activé.",
      "thisUserHasNotYetCreatedACareerProfile": "Cet utilisateur n'a pas encore créé de profil de carrière.",
      "thisWeek": "Cette semaine",
      "thriveStreams": "Thrive Streams",
      "title": "Titre",
      "tokenBalance": "Solde des jetons",
      "type": "Type",
      "unableToBatchDelete": "Erreur : Impossible de supprimer des utilisateurs par lots",
      "unknown": "Inconnu",
      "uploadedAt": "Téléchargé à",
      "uploadedBy": "Téléchargé par",
      "uploadedResume": "CV téléchargé",
      "userActivity": "Activité des utilisateurs",
      "userAgrees": "L'utilisateur accepte",
      "userJobApplicationsInfo": "Renseignements sur les demandes d'emploi des utilisateurs",
      "userNotYetActivated": "Utilisateur pas encore activé",
      "userPassword": "Mot de passe de l'utilisateur",
      "userPasswordConfirm": "Confirmer le mot de passe",
      "userPasswordNote": "Les mots de passe doivent comporter de 8 à 70 caractères et inclure : 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.",
      "userProfile": "Profil de l'utilisateur",
      "userProfileExcludedTags": "Étiquettes exclues",
      "userRegistrations": "Inscriptions des utilisateurs",
      "usersInvited": "Utilisateurs invités",
      "viewDetails": "Voir les détails",
      "weeklyAverage": "Moyenne hebdomadaire",
      "welcomePackage": "Forfait de bienvenue",
      "whatIsYourCurrentPriority": "Quelle est votre priorité actuelle?"
    }
  }]];
});